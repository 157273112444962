import { RootState } from './state';

export const getCancelConfirmModal = (state: RootState) => state.cancelConfirmModal;
export const getCancelConfirmModalSubscriptionId = (state: RootState) => getCancelConfirmModal(state).subscriptionId;
export const isCancelConfirmModalOpen = (state: RootState) => getCancelConfirmModal(state).isOpen;
export const getCancelConfirmModalSubscription = (state: RootState) =>
  getSubscriptionById(state, getCancelConfirmModalSubscriptionId(state));
export const getSubscriptions = (state: RootState) => state.subscriptions;
export const getSubscriptionById = (state: RootState, id: string | null) =>
  getSubscriptions(state).entities.find((subscription) => subscription.id === id);
export const isLoading = (state: RootState) => state.subscriptions.loading === 'pending';
export const getDetails = (state: RootState, subscriptionId: string) => state.details.entities[subscriptionId];
export const isDetailsOpen = (state: RootState, subscriptionId: string) => state.accordion.indexOf(subscriptionId) > -1;
export const isDetailsLoading = (state: RootState, subscriptionId: string) =>
  state.details.loading.indexOf(subscriptionId) > -1;
export const getLanguage = (state: RootState) => state.language;
export const getRegionalSettings = (state: RootState) => state.regionalSettings;
